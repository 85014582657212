const graphTutorial = {
  init: function () {
    this.bindUIActions();
  },

  /**
   * bindUIActions ties any UI functionality on page load
   * for the graphTutorial scripts
   */
  bindUIActions: function () {
    // open the overlay
    $('#tutorial-link').on('click', function () {
      var tutorialLink = $(this).attr('href');
      $('body').prepend('<div id="overlay"></div>').addClass('noscroll');
      $('#overlay').load(tutorialLink, function () {
        $(this).fadeIn();
      });
      return false;
    });

    // set up previous/next variables for tab
    // and previous/next controls
    var nextButton = '.site-tutorial-navigation .btn--next';
    var previousButton = '.site-tutorial-navigation .btn--previous';

    // set up tabs
    $(document).on('click', '.site-tutorial-tabs a', function () {
      // create a variable based on the tab's hyperlink
      var link = $(this).attr('data-link').replace('#', '.');
      // console.log(link);
      // hide whatever slide is being displayed
      $('.site-tutorial-slide-container').css('display', 'none');
      // show the first slide of the tab's category
      $(link).first().css('display', 'block');
      // switch to the active tab
      $('.site-tutorial-tabs li').not(this).removeClass('active');
      $(this).parent().addClass('active');

      setButtons();
      return false;
    });

    // reuseable function to get current, next
    // and previous tutorial slides
    var thisSlide, nextSlide, previousSlide;
    function getSlides() {
      thisSlide = $('.site-tutorial-slide-container:visible');
      nextSlide = $(thisSlide).next('.site-tutorial-slide-container');
      previousSlide = $(thisSlide).prev('.site-tutorial-slide-container');
    }

    /**
     * determines the active category when moving
     * forwards and backwards in the tutorial
     * and activates the corresponding tab
     */
    function activeTab(slide) {
      const tabClass = '.tab-' + slide.attr('data-activeTab');
      // console.log(tabClass);
      $('.site-tutorial-tabs li').removeClass('active');
      $(tabClass).addClass('active');
    }

    // Set active/inactive buttons for slide navigation
    function setButtons() {
      var $nextButton = $(nextButton),
        $previousButton = $(previousButton);

      getSlides();

      $nextButton.removeClass('btn--disabled');
      $previousButton.removeClass('btn--disabled');

      if ($(thisSlide).hasClass('first')) {
        $previousButton.addClass('btn--disabled');
      } else if ($(thisSlide).hasClass('last')) {
        $nextButton.addClass('btn--disabled');
      }
    }

    $(document).on('click', nextButton, function () {
      getSlides();
      // If this is the last slide, do nothing
      if ($(thisSlide).hasClass('last')) {
        return false;
      }
      activeTab(nextSlide);
      // display and hide the appropriate slides
      $(thisSlide).css('display', 'none');
      $(nextSlide).css('display', 'block');
      setButtons();
      return false;
    });

    $(document).on('click', previousButton, function () {
      getSlides();
      // If this is the first slide, do nothing
      if ($(thisSlide).hasClass('first')) {
        return false;
      }
      activeTab(previousSlide);
      // display and hide the appropriate slides
      $(thisSlide).css('display', 'none');
      $(previousSlide).css('display', 'block');
      setButtons();
      return false;
    });
  },
};

export default graphTutorial;
