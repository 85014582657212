import { closeOverlay } from './overlay';

const siteHandoff = {
  init: function () {
    this.bindUIActions();
  },

  bindUIActions: function () {
    var href;

    $(document).on('click', "a[target='_blank']", function () {
      href = $(this).attr('href');
      var overlayContent = $('.site-handoff').clone();
      $('body').prepend('<div id="overlay"></div>');
      $('#overlay').append(overlayContent).fadeIn();
      $(overlayContent).fadeIn();
      return false;
    });

    // set up the continue link to external site
    $('body').on('click', '.btn--continue', function () {
      window.open(href);
      closeOverlay();
    });
  },
};

export default siteHandoff;
