const graphInformation = {
  init: function () {
    this.bindUIActions();
  },

  bindUIActions: function () {
    $('#graph-information-link').on('click', function (e) {
      var top = $('.scenario-graph-information').offset().top;

      $('html, body').animate({
        scrollTop: top,
      });

      $('.back-to-top').removeClass('hide');

      e.preventDefault();
    });

    $('.back-to-top').on('click', function (e) {
      $('html, body').animate({
        scrollTop: 0,
      });

      $('.back-to-top').addClass('hide');

      e.preventDefault();
    });
  },
};

export default graphInformation;
