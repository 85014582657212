const escapeKey = {
  init: function () {
    this.bindUIActions();
  },

  bindUIActions: function () {
    $(document).keyup(function (e) {
      if (e.keyCode == 27) {
        $('.btn--close').trigger('click');
      } // escape key maps to keycode `27`
    });
  },
};

export default escapeKey;
