const landingSlides = {
  settings: {},

  init: function () {
    this.bindUIActions();
  },

  bindUIActions: function () {
    $('a.btn--more-info').on('click', function (e) {
      e.preventDefault();

      var $next = $(this).parents('.main-content-feature').next('.main-content-feature');
      var top = $next.offset().top;

      $('body').animate({
        scrollTop: top,
      });
    });
  },
};

export default landingSlides;
