const siteNavigation = {
  init: function () {
    this.bindUIActions();

    $('.main-nav li.active').parents('li').addClass('active');
  },

  bindUIActions: function () {
    $(document).mouseup(function (e) {
      var container = $('.secondary-nav--dropdown');

      if ($(e.target).parent().hasClass('open'))
        // ... or the parent is already open
        return false;
      // if the target of the click isn't the container...
      // ... nor a descendant of the container
      else if (!container.is(e.target) && container.has(e.target).length === 0) {
        // container.hide();
        container.parent().removeClass('open');
      }
    });

    // secondary dropdowns
    // we removed this when changing to
    // keyboard navigation
    $('.secondary-nav-dropdown-trigger').on('click', function (e) {
      if (/#/.test(this.href)) {
        e.preventDefault();
        $(this).parent().addClass('open');
        $('.secondary-nav li').not($(this).parent()).removeClass('open');
        return false;
      } else {
        return true;
      }
      // $(this).next().toggle();
    });

    // tertiary accordians
    $('.secondary-nav-faq-trigger').on('click', function () {
      var parent = $(this).parent();
      $('.secondary-nav-faq-subnav').not($(this).next()).hide();
      parent.toggleClass('expanded');
      $('.expanded').not($(this)).removeClass('expanded');
      $(this).next().toggle();
      return false;
    });
  },
};

export default siteNavigation;
