// bind and namespace the overlay click
// so it doesn't interfere with other clicks
function bindOverlay() {
  $(document).on('click.overlay', '#overlay', function (e) {
    if (e.target == this) {
      $(this).fadeOut();
    }
  });
}

function closeOverlay() {
  $('#overlay').fadeOut(function () {
    $(this).remove();
  });
  $('body').removeClass('noscroll');
}

function closeLoadingOverlay() {
  // when the graph has been drawn, hide the loading div
  $('.loading-animation, .loading-fallback, .graph-loading p').addClass('hide');
  $('.graph-loading').fadeOut('slow');
}

const overlay = {
  init: function () {
    bindOverlay();

    $(document).on('click', '.btn--close, .btn--cancel', function () {
      closeOverlay();
      return false;
    });
  },
};

export default overlay;
export { bindOverlay, closeOverlay, closeLoadingOverlay };
