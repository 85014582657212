import { closeOverlay, bindOverlay } from './overlay';

const siteAgreement = {
  init: function () {
    let overlayContent;

    if ($('#overlay').length) {
      overlayContent = $('#overlay')[0].outerHTML;
    }

    if (this.readCookie('siteAgreement')) {
      // console.log('cookie is on');
      siteAgreement.createCookie('siteAgreement', true, 15);
      $('.site-agreement-links a').addClass('active');
      $('.site-agreement-acknowledgement').css('display', 'none');
      bindOverlay();
    } else {
      // console.log('cookie is off');
      $(document).off('click.overlay');
      // show the agreement overlay if no cookie
      $('body').prepend(overlayContent);
      $('#overlay').fadeIn();
      // add the noscroll class to the body tag
      // to allow a user to scroll long overlay
      // content on shorter devices
      $('body:not(.layout--print, .layout--single)').addClass('noscroll');
      // disable clicking on the dosing information
      // links until a user has checked and set a cookie
      $('.site-agreement-links a').on('click', function () {
        return false;
      });
    }

    this.bindUIActions();
  },

  bindUIActions: function () {
    // set a cookie for 15 minutes when a user
    // has checked the agreement box
    // NOTE: currently set to 1 minute for testing
    $('body.home #educational-purposes').on('click', function (e) {
      siteAgreement.createCookie('siteAgreement', true, 15);
      $('.site-agreement-links a').addClass('active').off('click');
      bindOverlay();
      e.stopPropagation();
    });
    $(document).on('click', '.site-agreement.internal #educational-purposes', function (e) {
      siteAgreement.createCookie('siteAgreement', true, 15);
      $('body').removeClass('noscroll');
      bindOverlay();
      closeOverlay();
      e.stopPropagation();
    });

    // if we're on the homepage, remove noscroll
    if ($('body').hasClass('home')) {
      $('body').removeClass('noscroll');
    }
  },

  createCookie: function (name, value, minutes) {
    var expires;

    if (minutes) {
      var date = new Date();
      date.setTime(date.getTime() + minutes * 60000);
      expires = '; expires=' + date.toGMTString();
    } else {
      expires = '';
    }
    document.cookie = escape(name) + '=' + escape(value) + expires + '; path=/';
  },

  readCookie: function (name) {
    var dc = document.cookie;
    var prefix = name + '=';
    var begin = dc.indexOf('; ' + prefix);
    var end;
    if (begin == -1) {
      begin = dc.indexOf(prefix);
      if (begin !== 0) return null;
    } else {
      begin += 2;
      end = document.cookie.indexOf(';', begin);
      if (end == -1) {
        end = dc.length;
      }
    }
    return unescape(dc.substring(begin + prefix.length, end));
  },
};

export default siteAgreement;
