import 'core-js';

import escapeKey from './components/escape-key';
import graphInformation from './components/graph-information';
import graphTutorial from './components/graph-tutorial';
import landingSlides from './components/landing-slides';
import overlay, { closeLoadingOverlay } from './components/overlay';
import readMoreOverlay from './components/read-more-overlay';
import siteAgreement from './components/site-agreement';
import siteHandoff from './components/site-handoff';
import siteIsi from './components/site-isi';
import siteNavigation from './components/site-navigation';

(function () {
  overlay.init();

  // before anything else, set or check
  // the active (or nonexistent) site cookie
  // to verify a user has agreed to the terms of use
  siteAgreement.init();

  // set up the Graph Information button and overlay
  graphInformation.init();

  // set up the Tutorial button and overlay
  graphTutorial.init();

  // set up the Tutorial button and overlay
  siteHandoff.init();

  // set up the Read More overlays
  readMoreOverlay.init();

  // set up the global ISI (footer)
  siteIsi.init();

  // set up dropdown and accordian functionality of site navigation
  siteNavigation.init();

  // set up landing "slide show"
  landingSlides.init();

  // set up escape key binding to close popovers and modals
  escapeKey.init();

  // close loading overlay on scenario pages
  closeLoadingOverlay();

})();
