const readMoreOverlay = {
  init: function () {
    this.bindUIActions();
  },

  bindUIActions: function () {
    var href, overlayContent, readMoreContent;
    $(document).on('click', '#edit-dose-text a', function () {
      href = $(this).attr('href');
      href = href.substring(1);
      readMoreContent = $('table[data-readmore] #' + href + ' td').html();
      overlayContent =
        '<div class="readmore-content-overlay"><a href="#" class="btn btn--close">×</a>' +
        readMoreContent +
        '</div>';
      $('body').prepend('<div id="overlay"></div>');
      $('#overlay').append(overlayContent).fadeIn();
      $('.readmore-content-overlay').fadeIn();
      return false;
    });
  },
};

export default readMoreOverlay;
