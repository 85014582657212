const siteIsi = {
  init: function () {
    this.bindUIActions(window);

    $('.footer--scrolling')
      .clone()
      .prependTo('.footer')
      .removeClass('footer--scrolling')
      .addClass('footer--sliding footer--sliding--collapsed');
    $('.footer--sliding .footer-isi-content').addClass('noscroll');
  },

  bindUIActions: function () {
    $(document).on('click', '.footer--sliding--collapsed .footer-isi-content', function () {
      // prevent body from scrolling
      $('body').addClass('noscroll');
      $('.footer-isi-content').removeClass('noscroll');
      // slide out the isi
      $('.footer--sliding--collapsed').animate(
        {
          height: '+=400px',
        },
        200,
        function () {
          $(this).removeClass('footer--sliding--collapsed');
          $(this).addClass('footer--sliding--expanded');
        }
      );
    });

    $(document).on('click', '.footer--sliding--expanded .footer-isi-content', function () {
      // allow body to scroll again
      $('body').removeClass('noscroll');
      $('.footer-isi-content').scrollTop(0);
      $('.footer-isi-content').addClass('noscroll');
      // slide in the isi
      $('.footer--sliding--expanded').animate(
        {
          height: '-=400px',
        },
        200,
        function () {
          $(this).removeClass('footer--sliding--expanded');
          $(this).addClass('footer--sliding--collapsed');
        }
      );
    });

    /*
    this jawn is getting axed at some point
     */
    // if ( $('body').hasClass('layout--print') ) {
    //   return false;
    // } else {
    //   $(window).on("scroll", function() {
    //     var scrollHeight = $(document).height();
    //     var scrollPosition = $(window).height() + $(window).scrollTop();
    //     if ( $('.footer--scrolling').length > 0 )
    //       var footerOffset = $('.footer--scrolling').offset().top;
    //     if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
    //         // when scroll to bottom of the page
    //         $('.footer--sliding').hide();
    //         $('.footer--scrolling').show();
    //     } else if ( scrollPosition < ( footerOffset + 250 ) ) {
    //       $('.footer--sliding').show();
    //       $('.footer--scrolling').hide();
    //     }
    //   });
    // };

    /*
    js for is replacement
     */
    if ($('body').hasClass('layout--print')) {
      return false;
    } else {
      $(window).on('scroll', function () {
        var slidingHeight = $('.footer--sliding').height();
        var scrollPosition = $(window).height() + $(window).scrollTop() - slidingHeight;
        var footerOffset;
        if ($('.footer--scrolling').length > 0) footerOffset = $('.footer--scrolling').offset().top;
        if (scrollPosition > footerOffset) {
          // when scroll to bottom of the page
          $('.footer--sliding').css('display', 'none');
        } else {
          $('.footer--sliding').css('display', 'block');
        }
      });
    }

    $(document).on(
      'click',
      '.footer--sliding .footer-isi-tab a, .footer--scrolling .footer-isi-tab a',
      function () {
        var theTab = $(this).attr('href').replace('#', '');
        $('.footer-isi-tab a').not(this).removeClass('active');
        $('[href="#' + theTab + '"]').addClass('active');
        $('.footer-isi-content').removeClass('footer-isi-content--active');
        $('.' + theTab).addClass('footer-isi-content--active');
        return false;
      }
    );
  },
};

export default siteIsi;
